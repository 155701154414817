import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_PATH || 'https://' + window.location.host,
  withCredentials: false,
  https: false,
  headers: {
    Accept: 'application/json'
  }

});

export default {
  subscribe(email) {
    console.log('subscribe(' + email + ')');

    const params = new URLSearchParams();
    params.append('email', email);

    return apiClient.post('/api/subscribe.php', params);
    
  }, // subscribe

  unsubscribe(email) {
    console.log('unsubscribe(' + email + ')');

    const params = new URLSearchParams();
    params.append('email', email);

    return apiClient.post('/api/unsubscribe.php', params);
    
  } // unsubscribe
  
};
